import React from 'react';
import logo from '../../assets/images/invocom-logo.svg';
import robotImg from '../../assets/images/splash-robot.png';

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <div className="widget overflow-hidden">
        <div className="pluse-animation">
          <div className="splash-body">
            <img className="splash-logo" src={logo} alt="logo" />
            <img className="splash-robot" src={robotImg} alt="img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
