import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import unregister from './serviceWorker';
import setColorProperties from './registerColorProperties';

import App from './App';
import DisbaleScreen from './screens/DisbaleScreen';
import { isSupported } from './helpers/date-conversions';

import loadingGif from './assets/icons/loading.gif';

function MyComponent() {
  unregister();
  useEffect(() => {
    if (isSupported()) {
      setColorProperties();
    }
  }, []);
  const renderLoader = () => <img src={loadingGif} alt="loading" style={{ width: '25px' }} />;
  return (
    <React.Fragment>
      <Suspense fallback={renderLoader()}>{isSupported() ? <App /> : <DisbaleScreen />}</Suspense>
    </React.Fragment>
  );
}

ReactDOM.render(<MyComponent />, document.getElementById('root'));
